import React from "react";
import PropTypes from "prop-types";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../../../core/actions";
import pdf from '../../../resource/pdf.png'
import zip from '../../../resource/zip.jpeg'
import doc from '../../../resource/doc.jpeg'

const getIcon = (type, image) => {
    switch (type) {
        case "image/jpeg":
        case "image/png":
        case "image/gif":
        case "image/bmp":
            return image;
            break;
        case "application/zip":
        case "application/x-rar-compressed":
            return zip;
            break;
        case "application/pdf":
            return pdf;
            break;
        case "application/vnd.oasis.opendocument.presentation":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        case "application/msword":
        case "application/vnd.oasis.opendocument.spreadsheet":
        case "application/vnd.oasis.opendocument.text":
        case "application/vnd.ms-excel":
        case "text/plain":
            return doc;
            break;

    }
}

const ImagePreview = (props) => {

    const { listFiles, delFile, pending } = props

    let deleteFile = (id) => {
         delFile(id)
    }
    return (listFiles.map(({ name, preview, humanSize, type, id }) => (
        <div key={name} className="row items">
            <div className="col-3">
                <img width="40" src={getIcon(type, preview)} alt={name} />
            </div>
            <div className="col-5">

                <span className="nameFile">{humanSize}</span>
                <span className="nameFile">{name}</span>
            </div>

            <div className="col-4" style={{ textAlign: "right" }}>
                <button disabled={pending} onClick={() => deleteFile(id)} className="btn btn-danger">X</button> &nbsp;
            </div>
        </div>
    ))

    )
};



let mapStateToProps = state => {
    return {
        ...state,
        listFiles: state.fileReducer.listFiles,
        pending: state.httpReducer.pending,

    }
}
const mapDispatchToProps = (dispatch) => ({
    delFile: (id) => {
        dispatch(actions.fileRemove(id));
    }
});
let enhance = compose(
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(ImagePreview);


