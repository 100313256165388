import React, { useRef, useEffect, useState } from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { TextField } from '../../base/form/TextField';
import { required, email, atLeastOne } from '../../../core/helper/validator';
import { TextArea } from '../../base/form/TextArea';
import DropZoneField from '../../base/form/DropZoneField';
import { toast } from 'react-toastify';
import service from '../service';
import LoadingOverlay from 'react-loading-overlay';

import image from '../../../resource/success.png'


const FinishClaim = props => {
    const { handleSubmit, onSubmit, pending = true, upload, pendingUpload, match, } = props

    const { token } = match.params

    let handleOnDrop = (newFile) => {
        if (newFile.length === 0) {
            toast.info("sdfsd")
        } else {
            upload(newFile[0])
        }



    }

    return (
        <section style={{height: window.innerHeight+"px",textAlign:'center'}} className="testimonial py-5" id="testimonial">
            <div className="container">
                <div className="row ">
                    <div className="col-md-12 py-5  formbox text-white text-center ">
                        <div className=" ">
                            <div className="card-body">
                                <div className="row ">
                                    <div className="col-md-4">
                                        <img src={image} style={{ width: "50%" }} />
                                    </div>
                                    <div className="col-md-8">
                                        <h2 className="py-3">Successful claim!!!....What is next!</h2>
                                        <p>The platform will validate your claim and we will send you an email with the results obtained ... Thank you for using Tresure Beach</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}









let mapStateToProps = state => {
    return {
        ...state,
        pending: state.httpReducer.pending,
        pendingUpload: state.uploadReducer.pending

        // error: state.httpReducer.error,
        // initialValues: state.httpReducer.payload,


    }
}

const mapDispatchToProps = (dispatch) => ({
    onSubmit: (values, token) => {
        dispatch(service.claim(values, token))
    },
    upload: file => {
        dispatch(service.uploadFile(file))
    },

});



let enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'claim-form',
    }),
    withRouter
)

export default enhance(FinishClaim);

