import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css'
import ClaimForm from './modules/claim/views/ClaimForm';
import { createBrowserHistory } from 'history';
import Page404 from './modules/base/Page404';
import FinishClaim from './modules/claim/views/FinishClaim';
import InvalidClaim from './modules/claim/views/InvalidClaim';

const history = createBrowserHistory();
function App() {
  return (
    <div className="masthead">
      <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />

      <Router history={history}>
        <Switch>
          <Route path={`/dmo-claims/:token`} component={ClaimForm} />
          <Route path={`/dmo-claims-end/:token`} component={FinishClaim} />
          <Route path={`/dmo-claims-invalid/:token`} component={InvalidClaim} />
          <Route component={Page404} />

        </Switch>
      </Router>
    </div>
  );
}

export default App;
