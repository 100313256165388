import React, { useEffect } from "react";
import PropTypes from "prop-types";
import DropZone from "react-dropzone";
import ImagePreview from "./ImagePreview";
import Placeholder from "./Placeholder";
import ShowError from "./ShowError";
import { compose } from 'redux';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';

const allowType = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/bmp",
  "application/zip",
  "application/vnd.oasis.opendocument.presentation",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.ms-excel",
  "application/x-rar-compressed",
  "text/plain"
]

const DropZoneField = (props) => {
  const {
    handleOnDrop, label, disabled, pendingUpload,
    input: { onChange },
    listFiles,
    meta: { error, touched }
  } = props


  useEffect(() => {
    onChange(listFiles)
  }, [listFiles.length])


  return (
    <div className="preview-container">
      <label  >{label}  </label>
      <p style={{ fontSize: '12px', fontStyle: 'italic' }}>Please upload the evidences in this field, if there are several you can use a compressed file.</p>
      <LoadingOverlay
        active={pendingUpload}
        spinner
        text='Uploading file and updating file list...'
      >
        <DropZone
          disabled={disabled || pendingUpload}
          accept={allowType.join(",")}
          className="upload-container"
          onDrop={file => handleOnDrop(file, onChange)}
          multiple={false}
        >
          {props => <div className="row">
            <div style={{ padding: '10px' }} className="col-md-12 col-xs-12">
              {listFiles && listFiles.length > 0 ? (<ImagePreview />) : null
              }
            </div>
            {listFiles && listFiles.length == 0 ? <div className="col-md-12 col-xs-12">
              <Placeholder {...props} error={error} touched={touched} />
            </div>:null}
          </div>
          }
        </DropZone>
 
        <ShowError error={error} touched={touched} />
      </LoadingOverlay>

    </div>
  )
};

DropZoneField.propTypes = {
  error: PropTypes.string,
  handleOnDrop: PropTypes.func.isRequired,
  imagefile: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.file,
      name: PropTypes.string,
      preview: PropTypes.string,
      size: PropTypes.number
    })
  ),
  onChange: PropTypes.func,
  touched: PropTypes.bool
};


let mapStateToProps = state => {
  return {
    ...state,
    listFiles: state.fileReducer.listFiles,
    pendingUpload: state.uploadReducer.pending
  }
}
const mapDispatchToProps = (dispatch) => ({});
let enhance = compose(
  connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(DropZoneField);
