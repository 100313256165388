import React, {  } from 'react';

import image from '../../../resource/error.png'


const InvalidClaim = () => {

   

    return (
        <section style={{height: window.innerHeight+"px",textAlign:'center'}} className="testimonial py-5" id="testimonial">
            <div className="container">
                <div className="row ">
                    <div className="col-md-12 py-5  formbox text-white text-center ">
                        <div className=" ">
                            <div className="card-body">
                                <div className="row ">
                                    <div className="col-md-4">
                                        <img src={image} style={{ width: "50%" }} />
                                    </div>
                                    <div className="col-md-8">
                                        <h2 className="py-3">Invalid token!!!</h2>
                                        <p>The token used has expired or is not valid to perform the requested operation.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}








 

export default InvalidClaim;

