import React, { useRef, useEffect, useState } from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { TextField } from '../../base/form/TextField';
import { required, email, atLeastOne } from '../../../core/helper/validator';
import { TextArea } from '../../base/form/TextArea';
import DropZoneField from '../../base/form/DropZoneField';
import { toast } from 'react-toastify';
import service from '../service';
import LoadingOverlay from 'react-loading-overlay';



const ClaimForm = props => {
    const { handleSubmit, onSubmit, pending = true, upload, pendingUpload, match, } = props
    const { token } = match.params
    let handleOnDrop = (newFile) => {
        if (newFile.length === 0) {
            toast.info("This file is not allow")
        } else {
            upload(newFile[0])
        } 
    }

    React.useEffect(()=>{
        service.checkToken(token).then(r=>{         
            if(!r){
                 window.location.href=`/dmo-claims-invalid/${token}`
            }
        })
    },[])

    return (
        <section className="testimonial py-5" id="testimonial">
            <div className="container">
                <div className="row ">
                    <div className="col-md-4 py-5  formbox text-white text-center ">
                        <div className=" ">
                            <div className="card-body">
                                <img src="http://www.ansonika.com/mavia/img/registration_bg.svg" style={{ width: "30%" }} />
                                <h2 className="py-3">Claims</h2>
                                <p>If you are on this page it is because you have requested the claim of a resource in Tresure Beach please enter the data of the collection to validate the claim. Thank you so much</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 py-5 formbox">
                        <LoadingOverlay
                            active={pending}
                            spinner
                            text='Sending data Claims...'
                        >
                            <h4 className="pb-4">Please fill with your details</h4>
                            <form onSubmit={handleSubmit((values) => { onSubmit(values, token) })}>
                                <div className="form-row">
                                    
                                    <div className="form-group col-md-12">
                                        <Field
                                            name="reason"
                                            component={TextArea}
                                            label="Description"
                                            disabled={pending}
                                            type="text"
                                            id="description"
                                        />
                                    </div>

                                    <div className="form-group col-md-12">
                                        <Field
                                            name="evidences"
                                            component={DropZoneField}
                                            label="Evidences"
                                            disabled={pending}
                                            //validate={[atLeastOne]}
                                            type="file"
                                            id="evidences"
                                            handleOnDrop={handleOnDrop}
                                        />
                                    </div>
                                </div>



                                <div style={{ textAlign: "right" }} className="form-row">
                                    <div className="form-group col-md-12">
                                        <button disabled={pendingUpload} type="submit" className="btn btn-danger">Send</button>
                                    </div>
                                </div>
                            </form>
                        </LoadingOverlay>
                    </div>
                </div>
            </div>
        </section>
    );
}









let mapStateToProps = state => {
    return {
        ...state,
        pending: state.httpReducer.pending,
        pendingUpload: state.uploadReducer.pending

        // error: state.httpReducer.error,
        // initialValues: state.httpReducer.payload,


    }
}

const mapDispatchToProps = (dispatch) => ({
    onSubmit: (values, token) => {
        dispatch(service.claim(values, token))
    },
    upload: file => {
        dispatch(service.uploadFile(file))
    },

});



let enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'claim-form',
    }),
    withRouter
)

export default enhance(ClaimForm);

