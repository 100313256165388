import React from 'react';

export const TextArea = ({
  label,
  input,
  type,
  id,
  disabled,
  meta: { touched, invalid, error },
  ...custom
}) => (
    <div>      
      <label htmlFor={id}>{label}</label>
      <textarea {...input} id={id} disabled={disabled} className={(touched && invalid) ? 'form-control form-control-lg is-invalid' : 'form-control form-control-lg'} type={type} placeholder={label} ></textarea>
       {touched && ((error && <div className="invalid-feedback"> {error}</div>))}
    </div>
  )
