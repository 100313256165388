import React from 'react';

import { connect } from 'react-redux'

class Page0Record extends React.Component {


  render() {
    return (
      <div style={{height: window.innerHeight+"px",textAlign:'center'}}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="error-template">
                <h1>
                  Oops!</h1>
                <h2>
                  404 No encontrada</h2>
                <div className="error-details">
                  Lo sentimos, se ha producido un error. ¡No se encontró la página solicitada!                </div>
                <div className="error-actions">
                  
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
export default connect()(Page0Record);