import service from "../../core/services/service";
import { actions } from "../../core/actions";
import { toast } from 'react-toastify';
import { store } from "../../core/helper/store";



function claim(model, token) {
    if(model.evidences && model.evidences.length>0){
        model.evidences = model.evidences[0].preview 
    }
    console.log(model)
    return dispatch => {
        dispatch(actions.startHTTPRequest());
      
        service.post(`claim/claims/form/${token}`, model).then((r) => {
            dispatch(actions.stopRequestSuccess(r));
            window.location.href=`/dmo-claims-end/${token}`
        }).catch(r=>{
            dispatch(actions.stopRequestSuccess(""));
            toast.error("The operation could not be performed, check the fields and try again")
        })
         
    }
}

function uploadFile(file) {
    return dispatch => {
        dispatch(actions.startUpload());
        const formData = new FormData();
        formData.append('file', file);
        service.upload(formData).then((response) => {
            let file = {
                id: response.id,
                preview: process.env.REACT_APP_STATIC + response.filename,
                name: response.filename,
                humanSize: response.humanSize,
                type: response.type
            }
            dispatch(actions.stopUpload(file));
            dispatch(actions.fileAdd(file));
        })        
    }
}

function checkToken(token) {
    return service.post(`claim/claims/form/check/${token}`)
}


export default { uploadFile, claim,checkToken };

