
const types = {

    HTTP_PENDING: 'HTTP_PENDING',
    HTTP_ERROR: 'HTTP_ERROR',
    HTTP_SUCCESS: 'HTTP_SUCCESS',

   
    FILE_ADD: 'FILE_ADD',
    FILE_REMOVE: 'FILE_REMOVE',

    HTTP_PENDING_UPLOAD: 'HTTP_PENDING_UPLOAD',
    HTTP_SUCCESS_UPLOAD: 'HTTP_SUCCESS_UPLOAD',
}

const actions = {


    

    //HTTP
    startHTTPRequest: () => ({
        type: types.HTTP_PENDING,
    }),
    stopHTTPRequestError: (error) => ({
        type: types.HTTP_ERROR,
        error: error
    }),
    stopRequestSuccess: (payload) => ({
        type: types.HTTP_SUCCESS,
        payload: payload
    }),

    //File
    fileAdd: (file) => ({
        type: types.FILE_ADD,
        file: file
    }),
    fileRemove: (listFiles) => ({
        type: types.FILE_REMOVE,
        listFiles: listFiles
    }),
    startUpload: () => ({
        type: types.HTTP_PENDING_UPLOAD,
    }),
    stopUpload: (file) => ({
        type: types.HTTP_SUCCESS_UPLOAD,
        file: file
    }),

}



export { types, actions };
