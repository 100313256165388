import { combineReducers } from 'redux';
import httpReducer from './httpReducer'
import authReducer from './authReducer'
import { reducer as formReducer } from "redux-form";
import { routerReducer } from 'react-router-redux';
import fileReducer from './fileReducer'
import uploadReducer from './uploadReducer'




export default combineReducers({
    httpReducer,
    fileReducer,
    authReducer,
    uploadReducer,
    routing: routerReducer,
    form: formReducer,     
});
